import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { ColorService, desktop, tablet, getFontFamily } from 'services';

import {
  Icon,
  IconsNames,
  ContactOption,
  VKIcon,
  TelegramIcon,
  YoutubeIcon,
} from 'components/atoms';
import { Container } from 'components/layouts';
import { PATHS } from 'router/config';
import { CategoryNames } from 'context';

export const Footer = memo(() => (
  <FooterStyled>
    <Container>
      <Relative>
        <Grid>
          <Logo>
            <Link to="/">
              <IconLogo>
                <Icon name={IconsNames.logo} />
              </IconLogo>
            </Link>
          </Logo>
          <Contacts>
            <ContactOptionBox>
              <TitleContacts>Контакты</TitleContacts>
              <ContactOption
                linkTitle="+375 (29) 105-59-59"
                link="tel:+375291055959"
                subtitle="Контактный центр"
                fontsStyle={{ isBoldLink: true }}
              />
            </ContactOptionBox>
            <ContactOptionBox>
              <ContactOption
                linkTitle="info@teachmeskills.by"
                link="mailto:info@teachmeskills.by"
                subtitle="Вопросы по курсам"
                fontsStyle={{ isBoldLink: true }}
              />
            </ContactOptionBox>
            <SocialList>
              <SocialBtn href={PATHS.vk} target="_blank">
                <VKIcon fill={ColorService.WHITE} width={13} height={7.6} />
              </SocialBtn>
              <SocialBtn href={PATHS.telegram} target="_blank">
                <TelegramIcon fill={ColorService.WHITE} />
              </SocialBtn>
              <SocialBtn href={PATHS.youtube} target="_blank">
                <YoutubeIcon fill={ColorService.WHITE} />
              </SocialBtn>
            </SocialList>
          </Contacts>
          <Courses>
            <Title>Курсы</Title>
            <List>
              {Object.values(CategoryNames).map((category, index) => (
                <Li key={category}>
                  <Link
                    to={`${PATHS.courses}?filter=${
                      Object.keys(CategoryNames)[index]
                    }`}
                  >
                    {category}
                  </Link>
                </Li>
              ))}
            </List>
          </Courses>
          <MenuSite>
            <List>
              <Li>
                <Link to={`${PATHS.career}`} target="_blank">
                  <Title>Карьерный центр</Title>
                </Link>
              </Li>
              {/* <Li>
                <Link to={`${PATHS.prof}`}>
                  <Title>Профориентация</Title>
                </Link>
              </Li> */}
              <Li>
                <Link to={`${PATHS.blog}`} target="_blank">
                  <Title>Блог</Title>
                </Link>
              </Li>
            </List>
          </MenuSite>
          <Payment>
            <Title>Принимаем к оплате</Title>
            <ListCompanies>
              <CompanyImg name={IconsNames.visa} />
              <CompanyImg name={IconsNames.mastercard} />
              <CompanyImg name={IconsNames.maestro} />
              <CompanyImg name={IconsNames.mir} />
            </ListCompanies>
            {/* <Text>
              ООО «ТичМиСкилс» | УНП 692033963 Свидетельство о государственной
              регистрации № 692033963 от&nbsp;29.05.2020 выдано Узденским
              районным исполнительным комитетом
            </Text>
            <Address>
              220035, РБ. г. Минск, ул. Тимирязева, <br /> д.&nbsp;67,
              оф.&nbsp;802
            </Address> */}
          </Payment>
        </Grid>
      </Relative>
    </Container>
  </FooterStyled>
));

const FooterStyled = styled.footer`
  width: 100%;
  padding: 24px 0 48px;

  ${desktop} {
    padding: 32px 0 48px;
  }
`;

const Relative = styled.div`
  position: relative;
`;

const ContactOptionBox = styled.div`
  margin-bottom: 16px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'logo'
    'courses'
    'menu'
    'contacts'
    'payment';
  grid-template-columns: 1fr;

  ${tablet} {
    grid-template-areas:
      'logo courses payment'
      'contacts menu payment';
    grid-template-columns: 1.4fr 1fr 1.3fr;
  }

  ${desktop} {
    grid-template-areas:
      'logo courses menu payment'
      'contacts courses menu payment';
    grid-template-columns: 1.6fr 1fr 1.2fr 1.2fr;
  }
`;

const Logo = styled.div`
  grid-area: logo;
  justify-self: center;
  ${tablet} {
    justify-self: left;
  }
`;

const Courses = styled.div`
  grid-area: courses;
  margin-bottom: 32px;

  ${desktop} {
    margin-bottom: 0;
  }
`;

const MenuSite = styled.div`
  grid-area: menu;
  margin-bottom: 32px;

  Li:not(:last-child) {
    margin-bottom: 16px;
  }

  ${tablet} {
    margin-bottom: 0;
  }
`;

const Payment = styled.div`
  grid-area: payment;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${tablet} {
    align-items: flex-start;
  }

  ${desktop} {
    margin-bottom: 40px;
  }
`;

const Contacts = styled.div`
  grid-area: contacts;
  margin: 0 0 24px;

  ${tablet} {
    position: absolute;
    top: 56px;
    left: 0;
    margin: 0 0 36px 0;
    & > div > div > div {
      justify-content: flex-start;
    }
  }
`;

const IconLogo = styled.div`
  width: 232px;
  height: 24px;
  position: relative;
  margin-bottom: 32px;

  ${tablet} {
    margin-left: -14px;
  }
`;

const Title = styled.p`
  color: ${ColorService.BLACK};
  font-family: ${getFontFamily('medium')};
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
  text-align: center;

  ${tablet} {
    text-align: left;
  }
`;

const List = styled.ul`
  text-align: center;
  ${tablet} {
    text-align: left;
  }
`;

const Li = styled.li`
  color: ${ColorService.BLACK};
  font-family: ${getFontFamily()};
  font-size: 12px;
  line-height: 24px;

  a {
    transition: 0.6s;

    &:hover {
      transition: 0.6s;
      color: ${ColorService.GRAY_LIGHT} !important;
    }
  }
`;

// const Text = styled.p`
//   color: ${ColorService.GRAY};
//   font-family: ${getFontFamily()};
//   font-size: 12px;
//   line-height: 18px;
//   max-width: 230px;
//   white-space: break-spaces;
//   text-align: center;
//   ${tablet} {
//     text-align: left;
//   }
// `;

// const Address = styled.address`
//   color: ${ColorService.GRAY};
//   font-family: ${getFontFamily()};
//   font-size: 12px;
//   line-height: 18px;
//   margin: 16px 0 0 0;
//   text-align: center;

//   ${tablet} {
//     text-align: left;
//   }
// `;

const ListCompanies = styled.div`
  display: flex;
  flex-direction: row;
  gap: 19px;
  row-gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 26px;
  align-items: center;
`;

const CompanyImg = styled(Icon)`
  height: 24px;
  width: auto;
  object-fit: contain;
  object-position: center;

  &:first-child {
    height: 16px;
  }
`;

const SocialBtn = styled.a`
  width: 24px;
  height: 24px;
  background: ${ColorService.GRAY};
  margin: 0 4px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    transition: 0.6s;
    background: ${ColorService.BG_BLACK};
  }

  svg {
    height: 10px;
    fill: ${ColorService.BLACK};
  }
`;

const SocialList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  ${tablet} {
    justify-content: flex-start;
  }
`;

const TitleContacts = styled(Title)`
  ${tablet} {
    display: none;
  }
`;
